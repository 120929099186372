<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs" :externalFilter="filterByUserBerths"></pui-datatable>
	</div>
</template>

<script>
export default {
	name: 'departuremovementgrid',
	components: {},
	data() {
		return {
			modelName: 'departuremovement',
			userBerthIds: [],
			modelColumnDefs: {
				movementstatus: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				}
			}
		};
	},
	mounted() {
		this.userBerthIds = this.$store.getters.getUserBerthIds;
	},
	computed: {
		filterByUserBerths() {
			return {
				groups: [],
				groupOp: 'or',
				rules: [
					{ field: 'locationfrom', op: 'in', data: this.userBerthIds },
					{ field: 'berthfrom', op: 'in', data: this.userBerthIds }
				]
			};
		}
	}
};
</script>
